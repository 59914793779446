






import { Component, Vue } from "vue-property-decorator";
import { Tab, Tabs } from "vant";
import SupplyDetailList from "@/views/user-detail/list.vue";

@Component({
  components: {
    Tab,
    Tabs,
    SupplyDetailList,
  },
})
export default class UserDetail extends Vue {
  active = 0;
  //  <tabs v-model="active" sticky>
  //   <tab title="游戏">
  //     <supply-detail-list :type="0" />
  //   </tab>
  //   <tab title="实物">
  //     <supply-detail-list :type="1" />
  //   </tab>
  // </tabs>
}
